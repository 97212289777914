import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import { motion } from 'framer-motion'
import { fadeIn } from '@elements/Animations'
import Backdrop from '@elements/Modal/Backdrop'
import { CloseButton } from '@elements/Modal/Form'
import close from '@images/close.svg'
//import radlertourTeaser from '@images/events/Radler-Tour-Plakat-toppart.jpg'
import festltourTeaser from '@images/events/FestlTour-Teaser.jpg'
//import popupEventBg from '@images/events/popup-events-bg.jpg'
//import popupEventRadler from '@images/events/popup-events-radler.png'
//import popupEventFestl from '@images/events/popup-events-festl.png'

const PopupContainer = styled(motion.div)`
  ${tw`lg:w-[960px] h-auto sm:max-h-[95vh] bg-white w-[calc(100% - 20px)] sm:w-[90%] shadow-xl z-50 fixed`}
`
const ImageWrap = tw.div`relative max-w-master mx-auto w-full`

//const PopupWrap = tw.div`relative max-w-master mx-auto w-full`
//const ImageOverlay = tw.div`absolute top-0 left-0 max-w-master mx-auto w-full flex justify-between`

const ImagePopup = ({ handleClose }) => {
  const { imagePopupHandle } = useStateContext()

  return (
    <Backdrop onClick={handleClose}>
      <PopupContainer
        variants={fadeIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={() => imagePopupHandle(false)}>
          <img src={close} alt="Schliessen" />
        </CloseButton>
        <ImageWrap>
          <a
              href="/events/"
              className="inline-block"
          >
            <img
              className="inline-block object-contain sm:h-auto"
              src={festltourTeaser}
              alt="zur FestlTour"
            />
          </a>
        </ImageWrap>
        {/*
        <PopupWrap>
          <ImageWrap>
            <img
              className="inline-block object-contain sm:h-auto"
              src={popupEventBg}
              alt="Events"
            />
          </ImageWrap>
          <ImageOverlay>
            <a
                href="/radlertour/"
                className="inline-block"
            >
              <img
                className="inline-block object-contain sm:h-auto"
                src={popupEventRadler}
                alt="zur RadlerTour"
              />
            </a>
            <a
                href="/events/"
                className="inline-block"
            >
              <img
                className="inline-block object-contain sm:h-auto"
                src={popupEventFestl}
                alt="zur FestlTour"
              />
            </a>
          </ImageOverlay>
        </PopupWrap>
        */}
      </PopupContainer>
    </Backdrop>
  )
}
export default ImagePopup
